/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min.js'

const $ = require("jquery")

export const onInitialClientRender = () => {
  $(document).ready(function () {
	$('body').scroll(function() {
		if ($('body').scrollTop() > 50) {
			$('nav').addClass('shrink');
		} else {
			$('nav').removeClass('shrink');
		}
	});
 $('a[href^="#"]').on("click",function() {
 	$('.main section').css("scroll-snap-align", "none");
 	$('.footer').css("scroll-snap-align", "none");
    if (window.location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && window.location.hostname == this.hostname) {

      var target = $(this.hash);
      var distance = target.offset().top + $(window).height();
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: distance
        }, 1000,function(){
        	$('.main section').css("scroll-snap-align", "start");
          $('.footer').css("scroll-snap-align", "start");
        });
        return false;
      }
    }
	});

  });
}