module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"La Celia - Plataforma Terroir","short_name":"Terroir","description":"Plataforma Terroir de La Celia Wines","start_url":"/","background_color":"#29251f","theme_color":"#BA916A","display":"standalone","icon":"static/icons/icon_192.png","icons":[{"src":"static/icons/icon_192.png","sizes":"192x192","type":"image/png"},{"src":"static/icons/icon_512.png","sizes":"512x512","type":"image/png"}],"screenshots":[{"src":"static/screen/screen_1.jpg","sizes":"2880x1800","type":"image/jpg","description":"Plataforma Terroir Screenshot"},{"src":"static/screen/screen_2.jpg","sizes":"2880x1800","type":"image/jpg","description":"Plataforma Terroir Screenshot"},{"src":"static/screen/screen_3.jpg","sizes":"2880x1800","type":"image/jpg","description":"Plataforma Terroir Screenshot"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c9c5fae93beabda0bd13c6b8a7cdee70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
