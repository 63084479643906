// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clima-js": () => import("./../../../src/pages/clima.js" /* webpackChunkName: "component---src-pages-clima-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poligonos-js": () => import("./../../../src/pages/poligonos.js" /* webpackChunkName: "component---src-pages-poligonos-js" */),
  "component---src-pages-suelo-js": () => import("./../../../src/pages/suelo.js" /* webpackChunkName: "component---src-pages-suelo-js" */),
  "component---src-pages-vinos-js": () => import("./../../../src/pages/vinos.js" /* webpackChunkName: "component---src-pages-vinos-js" */)
}

